import React from 'react';

const OpenQuote = () => {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.736 23.424C25.632 19.968 29.088 14.304 29.088 8.064C29.088 2.976 26.016 -3.10526e-07 22.464 0C19.104 2.93741e-07 16.608 2.688 16.608 5.856C16.608 9.024 18.816 11.328 21.696 11.328C22.272 11.328 23.04 11.232 23.232 11.136C22.752 14.4 19.68 18.24 16.608 20.16L20.736 23.424ZM4.224 23.424C9.024 19.968 12.48 14.304 12.48 8.064C12.48 2.976 9.408 1.14139e-06 5.856 1.45192e-06C2.592 1.73727e-06 -2.76955e-07 2.688 0 5.856C2.76955e-07 9.024 2.304 11.328 5.184 11.328C5.76 11.328 6.432 11.232 6.624 11.136C6.144 14.4 3.168 18.24 0.096 20.16L4.224 23.424Z"
        fill="#D3DBDE"
      />
    </svg>
  );
};

export default OpenQuote;
