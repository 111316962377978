// extracted by mini-css-extract-plugin
export var videoOverlay = "StoryHero-module--videoOverlay--2rjVu";
export var coverImageShade = "StoryHero-module--coverImageShade--3a6Rw";
export var coverImage = "StoryHero-module--coverImage--5WQFY";
export var btnWrapper = "StoryHero-module--btnWrapper--1j3ga";
export var reactPlayer = "StoryHero-module--reactPlayer--28__M";
export var background = "StoryHero-module--background--F6q3u";
export var backgroundImage = "StoryHero-module--backgroundImage--JjIeE";
export var heroImage = "StoryHero-module--heroImage--3Qk7N";
export var heroText = "StoryHero-module--heroText--379Jk";
export var warYear = "StoryHero-module--warYear--1SY48";
export var heroLede = "StoryHero-module--heroLede--n2jHn";