import { Link } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import { nextStoryTitle, triangle, storyLink } from './NextSection.module.scss';

const NextSection = ({ next, color }) => {
  return (
    <div className={`pagePadding ${nextStoryTitle}`}>
      {next && (
        <Link
          className={`textColorWhite strong h4 varColor${color} ${storyLink}`}
          to={`/${next.slug}`}
        >
          Next: {next.title}
          <div className={`${triangle}`} />
        </Link>
      )}
    </div>
  );
};

NextSection.propTypes = {
  next: PropTypes.object,
};
export default NextSection;
