import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useRef, useState } from 'react';
import ReactPlayer from 'react-player/vimeo';
import PlayButton from '../PlayButton';
import {
  btnWrapper,
  coverImage,
  coverImageShade,
  reactPlayer,
  videoOverlay,
} from './VideoPlayer.module.scss';

const VideoPlayer = ({ cover, vimeo, wrapperClassName, color }) => {
  const playerRef = useRef(null);
  const [videoPlaying, setVideoPlaying] = useState(false);

  // Hide the overlay if the video is playing
  let overlayOpacity = videoPlaying ? '0' : '';
  let overlayPointerEvents = videoPlaying ? 'none' : '';

  return (
    <div className={`${wrapperClassName}`}>
      <div className={`sixteenNineBox`}>
        <div
          className={`${videoOverlay}`}
          style={{
            opacity: overlayOpacity,
            pointerEvents: overlayPointerEvents,
          }}
        >
          <button
            className={`${coverImageShade}`}
            onClick={() => setVideoPlaying(true)}
            style={{
              pointerEvents: overlayPointerEvents,
            }}
          >
            {cover && (
              <GatsbyImage
                image={cover && getImage(cover.localFile)}
                alt={cover.alternativeText}
                className={`${coverImage}`}
                loading="eager"
              />
            )}
          </button>
          <div className={`${btnWrapper}`}>
            <PlayButton
              color={color}
              // className={`${playBtn}`}
              onClick={() => setVideoPlaying(true)}
            />
          </div>
        </div>
        <ReactPlayer
          className={`${reactPlayer}`}
          width="100%"
          height="100%"
          url={vimeo}
          controls
          playsinline // Prevent Vimeo from automatically entering fullscreen
          playing={videoPlaying}
          onEnded={() => setVideoPlaying(false)}
          ref={playerRef}
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
