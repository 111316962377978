import React from 'react';

const OpenQuote = () => {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.352 0C3.456 3.456 0 9.12 0 15.36C0 20.448 3.072 23.424 6.624 23.424C9.984 23.424 12.48 20.736 12.48 17.568C12.48 14.4 10.272 12.096 7.392 12.096C6.816 12.096 6.048 12.192 5.856 12.288C6.336 9.024 9.408 5.184 12.48 3.264L8.352 0ZM24.864 0C20.064 3.456 16.608 9.12 16.608 15.36C16.608 20.448 19.68 23.424 23.232 23.424C26.496 23.424 29.088 20.736 29.088 17.568C29.088 14.4 26.784 12.096 23.904 12.096C23.328 12.096 22.656 12.192 22.464 12.288C22.944 9.024 25.92 5.184 28.992 3.264L24.864 0Z"
        fill="#D3DBDE"
      />
    </svg>
  );
};

export default OpenQuote;
