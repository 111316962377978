import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React from 'react';
import NextSection from '../components/storyPage/NextSection';
import StoryHero from '../components/storyPage/StoryHero';
import VideoPlayer from '../components/storyPage/VideoPlayer';
import CloseQuote from '../icons/CloseQuote';
import OpenQuote from '../icons/OpenQuote';
import Layout from '../Layout';
import getAccentColor from '../utils/getAccentColor';
import {
  heroDetailsContainer,
  quote,
  texturedBg,
  videoSpace,
} from './Story.module.scss';
import TranscriptButton from '../components/TranscriptButton';

const Story = ({ data }) => {
  const {
    title,
    war,
    hero,
    start_date,
    end_date,
    lede,
    vimeo_embed,
    cover_image,
    transcript,
    pull_quote,
  } = data.strapiStories;

  const actualOrder = data.strapiStories.order;
  const nextStoryData = data.allStrapiStories.nodes;

  const index = nextStoryData
    .map(function (e) {
      return e.order;
    })
    .indexOf(actualOrder);

  const nextIndex = index + 1;
  const color = getAccentColor(index);

  const bg = data.allFile.nodes[0].childImageSharp.gatsbyImageData;

  return (
    <Layout>
      <div className={`posRel`}>
        <GatsbyImage
          className={`${texturedBg}`}
          alt=""
          image={getImage(bg)}
          layout="full width"
        />

        <StoryHero
          title={title}
          war={war}
          hero={hero}
          start_date={start_date}
          end_date={end_date}
          lede={lede}
          color={color}
        />
        <div className={`${heroDetailsContainer} posRel`}>
          <VideoPlayer
            wrapperClassName={`${videoSpace} pagePadding`}
            vimeo={vimeo_embed}
            cover={cover_image}
            color={color}
          />
          <div className={`pagePadding textColorWhite pb60 mt24`}>
            <TranscriptButton
              transcript={transcript}
              color={color}
              isHome={false}
            />
            <div className={`mb72 varColor${color} ${quote}`}>
              <OpenQuote />
              <blockquote className={`mt16 mb20`}>
                {parse(pull_quote)}
              </blockquote>
              <CloseQuote />
            </div>
          </div>
        </div>

        <NextSection next={nextStoryData[nextIndex]} color={color} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    strapiStories(slug: { eq: $slug }) {
      order
      title
      war
      hero {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              quality: 100
            )
          }
        }
      }
      start_date
      end_date
      lede
      vimeo_embed
      cover_image {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              quality: 100
            )
          }
        }
      }
      transcript
      pull_quote
    }
    allStrapiStories(sort: { fields: [order], order: ASC }) {
      nodes {
        order
        title
        slug
      }
    }
    allFile(filter: { name: { eq: "white-grunge-textures-1" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 80)
        }
      }
    }
  }
`;

export default Story;
