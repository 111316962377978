import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';

import {
  background,
  backgroundImage,
  warYear,
  heroImage,
  heroText,
  heroLede,
} from './StoryHero.module.scss';

const StoryHero = ({ title, war, hero, start_date, end_date, lede, color }) => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { name: { eq: "white-grunge-textures-1" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  `);

  const bg = data.allFile.nodes[0].childImageSharp.gatsbyImageData;

  return (
    <div className={`${background} varBgColor varColor${color}`}>
      <h1 className={`srOnly`}>{title}</h1>
      <GatsbyImage
        className={`${backgroundImage}`}
        alt=""
        image={getImage(bg)}
        layout="full width"
      />
      <GatsbyImage
        className={`${heroImage}`}
        alt={hero.alternativeText}
        image={getImage(hero.localFile)}
      />

      <div className={`pagePadding ${heroText}`}>
        <div className={`${warYear}`}>
          <h1 className={`h4 mb12`}>{war}</h1>
          <h2 className={`h4 mb24 date`}>
            {start_date}—{end_date}
          </h2>
        </div>
        <div>
          <h3 className={`h1`}>{title}</h3>
          <div className={`mt24 ${heroLede}`}>{parse(lede)}</div>
        </div>
      </div>
    </div>
  );
};

export default StoryHero;
